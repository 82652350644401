import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import {
  getDetailSettlement,
  sendToSettlement,
} from "../../../actions/contractAction";
import backArrow from "../../../assets/img/icons/atras-icon.svg";
import send from "../../../assets/img/icons/send.svg";
import { convertMoneyFormat, isEmptyOrUndefined, message } from "../../../helpers/helpers";
import tableStyles from "../../Layouts/tableStyle.module.scss";
export const Settlement = () => {
  const location = useLocation();
  const [result, setresult] = useState(location.state?.result);
  const statusFinalized = location?.state?.statusFinalized;
  const contractInfo = location.state?.endContract?.data;
  const [isLoading, setIsLoading] = useState(false);
  const store = useSelector((state) => state);
  const token = store.loginReducer.Authorization;
  const history = useHistory();
  const handleBack = () => {
    history.push("/nomina/contratos");
  };
  useEffect(() => {
    if (statusFinalized) {
      getDetailsById();
    }
  }, []);

  const getDetailsById = async () => {
    try {
      const result = await getDetailSettlement(
        { contract: contractInfo.contract_id },
        token
      );
      if (result.success) {
        setresult(result);
      }
    } catch (error) {
      return message("error", "Error", error.message);
    }
  };
  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const res = await sendToSettlement(
        {
          liquidated_contract: result?.id_liquidated_contract,
          contract: contractInfo.contract_id,
        },
        token
      );

      if (res.success) {
        setIsLoading(false);
        history.push("/nomina/contratos");
        return message(
          "success",
          "Liquidación enviada",
          "Se ha enviado a contabilidad"
        );
      } else {
        setIsLoading(false);
        return message("error", "Ha ocurrido un error", res.message);
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
      return message("error", "Error", error.message);
    }
  };

  return (
    <>
      {isLoading && (
        <div className="loading">
          <Loader type="Oval" color="#003f80" height={100} width={100} />
        </div>
      )}

      <div className="w-100 container">
        <Row>
          <Col xs={10}>
            <h1 className={tableStyles.title}>
              <img
                onClick={() => handleBack()}
                src={backArrow}
                className={`mr-2 mb-2 cursorPointer`}
                style={{ marginLeft: "-30px" }}
                alt="icon"
              ></img>
              Detalle de liquidación
            </h1>
          </Col>
        </Row>
        <Row>
          <div>

            {result?.miniPayroll &&
              Object.keys(result?.miniPayroll).length > 0 &&
              <Card className={`${tableStyles.cardBoxContainer}`}>
                <Row className="mb-2">
                  <h6
                    className={`${tableStyles.darkBlueText} ${tableStyles.blackBottomBorder} pb-2`}
                  >
                    <b>Nómina</b>
                  </h6>
                </Row>
                <Row className="d-flex justify-content-between">
                  <Col className={`${tableStyles.darkGrayText} `} xs={6}>
                    <b>Días laborados</b>
                  </Col>
                  <Col className={`text-secondary text-end`} xs={6}>
                    ${result?.miniPayroll?.daysPendingPayroll}
                  </Col>
                </Row>
                <hr className="m-0"></hr>
                <Row className="d-flex justify-content-between ">
                  <Col className={`${tableStyles.darkGrayText} `} xs={6}>
                    <b>Devengado</b>
                  </Col>
                  <Col className={`text-secondary text-end`} xs={6}>
                    ${result?.miniPayroll?.total_accrued}
                  </Col>
                </Row>
                <hr className="m-0"></hr>

                <Row className="d-flex justify-content-between ">
                  <Col className={`${tableStyles.darkGrayText} `} xs={6}>
                    <b>Deducciones</b>
                  </Col>
                  <Col className={`text-secondary text-end`} xs={6}>
                    ${result?.miniPayroll?.total_deducted}
                  </Col>
                </Row>
                <hr className="m-0"></hr>

                <Row className="d-flex justify-content-between ">
                  <Col className={`${tableStyles.darkGrayText} `} xs={6}>
                    <b>Total</b>
                  </Col>
                  <Col className={`text-secondary text-end`} xs={6}>
                    ${result?.miniPayroll?.total_payment}
                  </Col>
                </Row>
              </Card>
            }
          </div>

        </Row>
        {/* /* ----------------- SETTLEMENT EMPLOYEE - SETTLEMENT PERIOD ----------------- */}
        <Row>
          <Col xs={6}>
            <Card className={`${tableStyles.cardBoxContainer}`}>
              <Row className="mb-2">
                <h6
                  className={`${tableStyles.darkBlueText} ${tableStyles.blackBottomBorder} pb-2`}
                >
                  <b>Empleado a liquidar</b>
                </h6>
              </Row>
              <Row className="d-flex justify-content-between">
                <Col className={`${tableStyles.darkGrayText} `} xs={6}>
                  <b>Nombre</b>
                </Col>
                <Col className={`text-secondary text-end`} xs={6}>
                  {contractInfo?.candidate}
                </Col>
              </Row>
              <hr className="m-0"></hr>
              <Row className="d-flex justify-content-between">
                <Col className={`${tableStyles.darkGrayText} `} xs={6}>
                  <b>Documento de identidad</b>
                </Col>
                <Col className={`text-secondary text-end`} xs={6}>
                  {contractInfo?.cand_docnumber}
                </Col>
              </Row>
              <hr className="m-0"></hr>
              <Row className="d-flex justify-content-between pb-3">
                <Col className={`${tableStyles.darkGrayText} `} xs={6}>
                  <b>Cargo</b>
                </Col>
                <Col className={`text-secondary text-end`} xs={6}>
                  {contractInfo?.position}
                </Col>
              </Row>
            </Card>
          </Col>
          <Col xs={6}>
            <Card className={`${tableStyles.cardBoxContainer}`}>
              <Row className="mb-2">
                <h6
                  className={`${tableStyles.darkBlueText} ${tableStyles.blackBottomBorder} pb-2`}
                >
                  <b>Periodo de liquidación</b>
                </h6>
              </Row>
              <Row className="d-flex justify-content-between">
                <Col className={`${tableStyles.darkGrayText} `} xs={6}>
                  <b>Fecha de inicio de contrato</b>
                </Col>
                <Col className={`text-secondary text-end`} xs={6}>
                  {contractInfo?.star_date}
                </Col>
              </Row>
              <hr className="m-0"></hr>
              <Row className="d-flex justify-content-between">
                <Col className={`${tableStyles.darkGrayText} `} xs={6}>
                  <b>Fecha de terminación de contrato</b>
                </Col>
                <Col className={`text-secondary text-end`} xs={6}>
                  {statusFinalized
                    ? moment(result?.end_date).format("DD/MM/YYYY")
                    : moment(location?.state?.endContractDate).format(
                      "DD/MM/YYYY"
                    )}
                </Col>
              </Row>
              <hr className="m-0"></hr>
              <Row className="d-flex justify-content-between pb-3">
                <Col className={`${tableStyles.darkGrayText} `} xs={6}>
                  <b>Tiempo total laborado</b>
                </Col>
                <Col className={`text-secondary text-end`} xs={6}>
                  {result?.total_workdays + " Días"}
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        {/* /* --------------------------- PAYMENT SETTLEMENT - DEDUCTIONS--------------------------- */}
        <Row className="mt-2">
          <Col xs={6}>
            <Card className={`${tableStyles.cardBoxContainer}`}>
              <Row className="mb-2">
                <h6
                  className={`${tableStyles.darkBlueText} ${tableStyles.blackBottomBorder} pb-2`}
                >
                  <b>Liquidación de pagos</b>
                </h6>
              </Row>
              <>
                <Row className="d-flex justify-content-between">
                  <Col className={`${tableStyles.darkGrayText} `} xs={6}>
                    <b>Salario pendiente por pagar</b>
                  </Col>
                  <Col className={`text-secondary text-end`} xs={6}>
                    {convertMoneyFormat(result?.settlement_payments?.base_salary ?? 0)}
                  </Col>
                </Row>
                <hr className="m-0"></hr>
                <Row className="d-flex justify-content-between">
                  <Col className={`${tableStyles.darkGrayText} `} xs={6}>
                    <b>Horas extras</b>
                  </Col>
                  <Col className={`text-secondary text-end`} xs={6}>
                    {convertMoneyFormat(result?.settlement_payments?.overtime ?? 0)}
                  </Col>
                </Row>
                <hr className="m-0"></hr>
                <Row className="d-flex justify-content-between">
                  <Col className={`${tableStyles.darkGrayText} `} xs={6}>
                    <b>Pre-aviso</b>
                  </Col>
                  <Col className={`text-secondary text-end`} xs={6}>
                    {convertMoneyFormat(result?.settlement_payments?.pre_notice ?? 0)}
                  </Col>
                </Row>
                <hr className="m-0"></hr>
                <Row className="d-flex justify-content-between">
                  <Col className={`${tableStyles.darkGrayText} `} xs={6}>
                    <b>Cesantía</b>
                  </Col>
                  <Col className={`text-secondary text-end`} xs={6}>
                    {convertMoneyFormat(result?.settlement_payments?.severence ?? 0)}
                  </Col>
                </Row>
                <hr className="m-0"></hr>
                <Row className="d-flex justify-content-between">
                  <Col className={`${tableStyles.darkGrayText} `} xs={6}>
                    <b>Salario vacaciones</b>
                  </Col>
                  <Col className={`text-secondary text-end`} xs={6}>
                    {convertMoneyFormat(result?.settlement_payments?.vacations ?? 0)}
                  </Col>
                </Row>
                <hr className="m-0"></hr>
                <Row className="d-flex justify-content-between">
                  <Col className={`${tableStyles.darkGrayText} `} xs={6}>
                    <b>Salario navidad</b>
                  </Col>
                  <Col className={`text-secondary text-end`} xs={6}>
                    {convertMoneyFormat(result?.settlement_payments?.christmas_salary ?? 0)}
                  </Col>
                </Row>
              </>
              <hr className="m-0"></hr>
              <Row className="d-flex justify-content-between pb-2">
                <Col className={`${tableStyles.darkBlueText} pt-3 `} xs={6}>
                  <b>Total devengado</b>
                </Col>
                <Col
                  className={`${tableStyles.darkGrayText}  text-end pt-3`}
                  xs={6}
                >
                  <b>
                    {convertMoneyFormat(
                      result?.settlement_payments?.total_accrued ?? 0
                    )}
                  </b>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col xs={6}>
            <Card
              className={`${tableStyles.cardBoxContainer}`}
              style={{ minHeight: "100%" }}
            >
              <Row className="mb-2">
                <h6
                  className={`${tableStyles.darkBlueText} ${tableStyles.blackBottomBorder} pb-2`}
                >
                  <b>Deducciones</b>
                </h6>
              </Row>
              <>
                <Row className="d-flex justify-content-between">
                  <Col className={`${tableStyles.darkGrayText} `} xs={6}>
                    <b> Salario pagado</b>
                  </Col>
                  <Col className={`text-secondary text-end`} xs={6}>
                    {convertMoneyFormat(Number(result?.deductions?.salary_paid ?? 0).toFixed(2)
                    )}
                  </Col>
                </Row>
                <hr className="m-0"></hr>
                <Row className="d-flex justify-content-between">
                  <Col className={`${tableStyles.darkGrayText} `} xs={6}>
                    <b>SFS </b>
                  </Col>
                  <Col className={`text-secondary text-end`} xs={6}>
                    {convertMoneyFormat(Number(result?.deductions?.SFS ?? 0).toFixed(2))}
                  </Col>
                </Row>
                <hr className="m-0"></hr>
                <Row className="d-flex justify-content-between">
                  <Col className={`${tableStyles.darkGrayText} `} xs={6}>
                    <b>SVDS </b>
                  </Col>
                  <Col className={`text-secondary text-end`} xs={6}>
                    {convertMoneyFormat(Number(result?.deductions?.SVDS ?? 0).toFixed(2)
                    )}
                  </Col>
                </Row>
                <hr className="m-0"></hr>
                <Row className="d-flex justify-content-between">
                  <Col className={`${tableStyles.darkGrayText} `} xs={6}>
                    <b>Impuesto sobre salario (ISR) </b>
                  </Col>
                  <Col className={`text-secondary text-end`} xs={6}>
                    {convertMoneyFormat(Number(result?.deductions?.ISR ?? 0).toFixed(2)
                    )}
                  </Col>
                </Row>
                <hr className="m-0"></hr>
                <Row className="d-flex justify-content-between">
                  <Col className={`${tableStyles.darkGrayText} `} xs={6}>
                    <b>Saldo a favor ISR </b>
                  </Col>
                  <Col className={`text-secondary text-end`} xs={6}>
                    {convertMoneyFormat(Number(result?.deductions?.balance ?? 0).toFixed(2)
                    )}
                  </Col>
                </Row>
                <hr className="m-0"></hr>
              </>
              {result?.deductions?.detailsDeductions?.length > 0 ?
                result?.deductions?.detailsDeductions?.map((deduction) => {
                  return (
                    <>
                      <Row className="d-flex justify-content-between">
                        <Col className={`${tableStyles.darkGrayText} `} xs={6}>
                          <b>{deduction.subscriptionName}</b>
                        </Col>
                        <Col className={`text-secondary text-end`} xs={6}>
                          ${deduction.valueSubscription}
                        </Col>
                      </Row>
                      <hr className="m-0"></hr>
                    </>
                  );
                }) : null
              }
              <Row
                className="d-flex justify-content-between pb-2"
                style={{ marginTop: "10%" }}
              >
                <Col className={`${tableStyles.darkBlueText} pt-3 `} xs={6}>
                  <b>Total deducciones</b>
                </Col>
                <Col
                  className={`${tableStyles.darkGrayText}  text-end pt-3`}
                  xs={6}
                >
                  <b>
                    {isEmptyOrUndefined(result?.deductions?.total_deductions)
                      ? convertMoneyFormat(0)
                      : convertMoneyFormat(Number(result?.deductions?.total_deductions ?? 0).toFixed(2))
                    }
                  </b>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <hr></hr>
        {/* //SI Y SOLO SI EL CONTRATO ESTÁ FINALIZADO */}
        {result?.status == "loaded" && (
          <div className={`mt-2 pb-2 p-0 d-flex justify-content-end`}>
            <Button
              variant="outline-primary"
              onClick={() => handleBack()}
              className={tableStyles.btnSecondary}
            >
              Cancelar
            </Button>
            &nbsp;
            <Button
              className={`${tableStyles.btnPrimary} ml-2`}
              onClick={() => handleSubmit()}
            // disabled={allowSend ? false : true}
            >
              <img className="cursorPointer" src={send} />
              &nbsp; Enviar a contabilidad
            </Button>
          </div>
        )}
      </div>
    </>
  );
};
